import React, { useState } from 'react';
import './UnitBox.scss';
import UnitCard from '../UnitCard/UnitCard';
import UnitPreview from '../UnitPreview/UnitPreview'
import { useSidePanel } from '../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../context/FlashMessageContext';
import useUnitIndex from '../../../../hooks/userUnits/useUnitIndex';
import useUserUnitShow from '../../../../hooks/userUnits/useUserUnitShow';

function UnitBox({ mode }) {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const { userUnits, loading: indexLoading, error: indexError } = useUnitIndex(refreshTrigger);
  const { showUserUnit, error: showError } = useUserUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewUnitClick = (previewUnit) => {
    showUserUnit(previewUnit.id)
      .then(unit => {
        openSidePanel(<UnitCard unit={unit.data} />);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', showError);
      });
  };

  const handleRefresh = () => {
    setRefreshTrigger(!refreshTrigger); // Toggle the trigger to force re-fetching user units
  };

  if (indexLoading) return <p>Loading units...</p>;
  if (indexError) return <p>Error loading units: {indexError.message}</p>;

  return (
    <div id='gs-user-unit-box'>
      {userUnits.data.map((unit) => (
        <UnitPreview
          key={unit.id}
          unit={unit}
          viewUnitClick={viewUnitClick}
          refreshUnits={handleRefresh}
          mode={mode}
        />
      ))}
    </div>
  );
}

export default UnitBox;
