import React from 'react';
import './EquipCardHeading.scss';
import physicalIcon from '../../../../assets/images/icons/equip_type/physical.png';
import magicIcon from '../../../../assets/images/icons/equip_type/magic.png';
import defenceIcon from '../../../../assets/images/icons/equip_type/defence.png';
import supportIcon from '../../../../assets/images/icons/equip_type/support.png';
import healIcon from '../../../../assets/images/icons/equip_type/heal.png';

function EquipCardHeading({ name, equipment_type }) {
  const getEquipTypeIconImg = (equipType) => {
    switch(equipType) {
      case 'physical':
        return <img src={physicalIcon} alt={`${equipment_type} icon`} />
      case 'magic':
        return <img src={magicIcon} alt={`${equipment_type} icon`} />
      case 'defence':
        return <img src={defenceIcon} alt={`${equipment_type} icon`} />
      case 'support':
        return <img src={supportIcon} alt={`${equipment_type} icon`} />
      case 'heal':
        return <img src={healIcon} alt={`${equipment_type} icon`} />
      default:
      return <img src={supportIcon} alt={`${equipment_type} icon`} />;
    }
  }

  const equipTypeImg = getEquipTypeIconImg(equipment_type.toLowerCase())
  return (
    <div className='gs-equip-card-heading'>
      {equipTypeImg}
      <h2 className=''>{name}</h2>
      {equipTypeImg}
    </div>
  );
}

export default EquipCardHeading;
