import React from 'react';
import '../../styles/pages/UnitBox.scss';
import { useParams } from 'react-router-dom';
import { UnitPreview, UnitCard } from '../user/Units';
import { useSidePanel } from '../../context/SidePanelContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import useUnitBox from '../../hooks/unitBox/useUnitBox';
import useUserUnitShow from '../../hooks/userUnits/useUserUnitShow';

function UnitBox() {
  const { box_id } = useParams();
  const { units, loading: boxLoading, error: boxError } = useUnitBox(box_id);
  const { showUserUnit, error: showError } = useUserUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewUnitClick = (previewUnit) => {
    showUserUnit(previewUnit.id)
      .then(unit => {
        openSidePanel(<UnitCard unit={unit.data} />);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', showError);
      });
  };


  if (boxLoading) return <p>Loading units...</p>;
  if (boxError) return <p>Error loading units: {boxError.message}</p>;

  return (
    <div id='gs-public-user-unit-box'>
      {units.data.map((unit) => (
        <UnitPreview
          key={unit.id}
          unit={unit}
          viewUnitClick={viewUnitClick}
          refreshUnits={''}
          mode={'show'}
        />
      ))}
    </div>
  );
}

export default UnitBox;
