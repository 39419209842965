import React, { createContext, useState, useContext } from 'react';

const SidePanelContext = createContext({
  isOpen: false,
  setIsOpen: () => {}, // Initially empty functions
  content: null,
  setContent: () => {},
});

export const SidePanelProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);

  const openSidePanel = (newContent) => {
    setIsOpen(true);
    setContent(newContent);
  };

  const closeSidePanel = () => {
    setIsOpen(false);
    setContent(null); // Reset content on close
  };

  const value = { isOpen, setIsOpen, content, openSidePanel, closeSidePanel };

  return (
    <SidePanelContext.Provider value={value}>
      {children}
    </SidePanelContext.Provider>
  );
};

export const useSidePanel = () => useContext(SidePanelContext);
