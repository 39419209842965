import React from 'react';
import './Panel.scss';

const Panel = ({ children, width, marginBottom }) => {
  const style = {
    width: width,
    marginBottom: (marginBottom || '0px'),
  };

  return (
    <div className="gs-panel" style={style}>
      {children}
    </div>
  );
};

export default Panel;
