import React from 'react';
import BasicFormField from '../../../../../common/BasicFormField';
import BasicFormFieldContainer from '../../../../../common/BasicFormFieldContainer';
import Panel from '../../../../../common/Panel';

function TasmonStatsPanel({ formData, handleInputChange, unit }) {
  return (
    <Panel>
      <h3>Tasmon Stats</h3>
      <BasicFormFieldContainer>
        <BasicFormField
          layout="column"
          label="HP"
          name="tasmonHP"
          type="number"
          value={formData.tasmon_hp}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_tasmon_hp.toString()}
        />
        <BasicFormField
          layout="column"
          label="ATK"
          name="tasmonATK"
          type="number"
          value={formData.tasmon_atk}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_tasmon_atk.toString()}
        />
        <BasicFormField
          layout="column"
          label="DEF"
          name="tasmonDEF"
          type="number"
          value={formData.tasmon_def}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_tasmon_def.toString()}
        />
      </BasicFormFieldContainer>
    </Panel>
  );
}

export default TasmonStatsPanel;
