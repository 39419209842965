import React, { useState } from 'react';
import './DeleteIcon.scss';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import DialogBox from '../../../../common/DialogBox';
import useUserUnitDelete from '../../../../../hooks/userUnits/useUserUnitDelete';
import { useFlashMessage } from '../../../../../context/FlashMessageContext';

function DeleteIcon({ unit, refreshUnits }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { deleteUnit, isLoading } = useUserUnitDelete();
  const { addMessage } = useFlashMessage();

  const confirmDelete = () => {
    deleteUnit(unit.id)
      .then(() => {
        addMessage(`Successfuly ${unit.attributes.name} removed`, 'success');
        setShowConfirmDialog(false);
        refreshUnits()
      })
      .catch(error => {
        addMessage(`Failed to remove ${unit.attributes.name}`, 'danger');
        console.error('Error:', error);
      });
  };

  return (
    <>
      <div className='gs-user-unit-delete'>
        <RemoveCircleOutlineOutlined onClick={() => setShowConfirmDialog(true)}/>
      </div>
      {showConfirmDialog && <DialogBox isOpen={showConfirmDialog} closeModal={() => setShowConfirmDialog(false)}>
          <p>Are you sure you want to delete {unit.attributes.name}?</p>
          <button className='primary' onClick={confirmDelete} disabled={isLoading}>YES</button>
          <button className='secondary' onClick={() => setShowConfirmDialog(false)}>NO</button>
      </DialogBox>}
    </>
  );
}

export default DeleteIcon;
