import React from 'react';
import './EquipPreview.scss';
import LbIconSvg from '../../../common/LbIconSvg';
import DeleteIcon from './DeleteIcon/DeleteIcon';

function EquipPreview({ equip, viewEquipClick, refreshEquips, mode }) {
  const isShowMode = (mode === 'show')

  const getBoxShadowClass = (equipment_type) => {
    switch(equipment_type.toLowerCase()) {
      case 'physical':
        return 'gs-user-physical-boxshadow';
      case 'magic':
        return 'gs-user-magic-boxshadow';
      case 'defence':
        return 'gs-user-defence-boxshadow';
      case 'support':
        return 'gs-user-support-boxshadow';
      case 'heal':
        return 'gs-user-heal-boxshadow';
      default:
        return 'gs-user-physical-boxshadow';
    }
  };

  const renderModeIcon = () => {
    if (isShowMode) {
      return (
        <div className='gs-user-equip-lb'>
          <LbIconSvg size={100} number={equip.attributes.lb} />
        </div>
      );
    } else {
      return <DeleteIcon equip={equip} refreshEquips={refreshEquips} />;
    }
  }

  return (
    <div className="gs-user-equip-preview">
      <div className="gs-user-equip-preview-icon">
        {renderModeIcon()}
        <div className={`gs-user-equip-preview-icon-img ${getBoxShadowClass(equip.attributes.equipment_type)}`}>
          <img src={equip.attributes.max_icon_img_url} onClick={() => viewEquipClick(equip)} alt={equip.attributes.name}/>
        </div>
      </div>
      <div className="gs-user-equip-preview-name">
        <span>{equip.attributes.name}</span>
      </div>
    </div>
  );
}

export default EquipPreview;
