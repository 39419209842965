import React from 'react';
import './BasicFormFieldContainer.scss';

function BasicFormFieldContainer({ children }) {
  return (
    <div className="gs-form-field-container">
      {children}
    </div>
  );
}

export default BasicFormFieldContainer;
