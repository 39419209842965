import React from 'react';
import './MinMaxControls.scss';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

function MinMaxControls({ setFormData, unit }) {
  const setMaxValues = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      lvl: unit.attributes.max_lvl.toString(),
      lb: unit.attributes.max_lb.toString(),
      tasmon_hp: unit.attributes.max_tasmon_hp.toString(),
      tasmon_atk: unit.attributes.max_tasmon_atk.toString(),
      tasmon_def: unit.attributes.max_tasmon_def.toString(),
    }));
  };

  const setMinValues = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      lvl: '1',
      lb: '0',
      tasmon_hp: '0',
      tasmon_atk: '0',
      tasmon_def: '0',
    }));
  };

  return (
    <div className='gs-add-unit-form-controls'>
      <button className='gs-add-unit-max-btn' onClick={setMaxValues} title='max stats'>
        <AddCircleOutline />
      </button>
      <button className='gs-add-unit-min-btn' onClick={setMinValues} title='min stats'>
        <RemoveCircleOutline />
      </button>
    </div>
  );
}

export default MinMaxControls;
