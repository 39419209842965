import React from 'react';
import './UnitStatList.scss';

function UnitStatList({ stats, tasStats }) {
  return (
    <ul className='gs-unit-stats-list'>
      <li className='gs-stat-hp'>
        <span className='gs-stat-title '>HP:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.hp}</span>
          <span className='gs-tas-stat-data'>+{tasStats.hp}</span>
        </div>
      </li>
      <li className='gs-stat-atk'>
        <span className='gs-stat-title'>ATK:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.atk}</span>
          <span className='gs-tas-stat-data'>+{tasStats.atk}</span>
        </div>
      </li>
      <li className='gs-stat-def'>
        <span className='gs-stat-title'>DEF:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.def}</span>
          <span className='gs-tas-stat-data'>+{tasStats.def}</span>
        </div>
      </li>
    </ul>
  );
}

export default UnitStatList;
