import React from 'react';
import './EquipAllStatsList.scss';
import DataDisplayRow from '../../../../common/DataDisplayRow'
import Panel from '../../../../common/Panel'

function EquipAllStatsList({ equip }) {
  return (
    <Panel width='100%'>
      <div className='gs-user-equip-all-stats-container'>
        <DataDisplayRow name='Equip Type' value={equip.equipment_type} />
        <DataDisplayRow name='Rarity' value={equip.rarity} />
        <DataDisplayRow name='Level' value={`${equip.lvl}/${equip.max_lvl}`} />
        <DataDisplayRow name='Limit Break' value={`${equip.lb}/${equip.max_lb}`} />
        <DataDisplayRow name='CT' value={equip.ct} />
        <DataDisplayRow name='Break' value={equip.break} />
        <DataDisplayRow name='Damage' value={equip.damage} />
        <DataDisplayRow name='Damage Element' value={equip.damage_type} />
      </div>
    </Panel>
  );
}

export default EquipAllStatsList;
