import React, { useState, useEffect, useMemo } from 'react';
import './AddEquipForm.scss';
import MinMaxControls from './MinMaxControls/MinMaxControls'
import Panel from '../../../../common/Panel';
import BasicFormFieldContainer from '../../../../common/BasicFormFieldContainer';
import BasicFormField from '../../../../common/BasicFormField';
import { useSidePanel } from '../../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../../context/FlashMessageContext';

const BASE_URL = process.env.REACT_APP_API_URL;

function AddEquipForm({ equip }) {
  const initialFormData = useMemo(() =>  ({
    equip_id: equip.id,
    lvl: '0',
    lb: '0',
  }), [equip.id]);

  const [formData, setFormData] = useState(initialFormData);
  const { closeSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  useEffect(() => {
    setFormData(initialFormData);
  }, [equip.id, initialFormData])

  const mapInputNameToStateKey = (inputName) => {
    const mapping = {
      level: 'lvl',
      limitBreak: 'lb'
    };

    return mapping[inputName] || inputName;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const stateKey = mapInputNameToStateKey(name);
    setFormData(prevFormData => ({
      ...prevFormData,
      [stateKey]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(BASE_URL)
    try {
      const response = await fetch(`${BASE_URL}/user_equips`, { // Replace with your actual endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}` // Assuming you store your token in localStorage
        },
        body: JSON.stringify({ user_equip: formData })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await response.json();
      addMessage(`Succesfully added ${equip.attributes.name}`, 'success');
      setFormData(initialFormData)
      closeSidePanel()
    } catch (error) {
      addMessage(`Failed to add ${equip.attributes.name}`, 'danger');
      console.error('Error:', error);
      // Handle errors (e.g., showing an error message to the user)
    }
  };

  return (
    <>
      <MinMaxControls setFormData={setFormData} equip={equip} />
      <form id={`equip-${equip.id}`} className='gs-add-equip-form' onSubmit={handleSubmit} >
        <Panel>
          <BasicFormFieldContainer>
            <BasicFormField
              layout="row"
              label="LVL"
              name="level"
              type="number"
              value={formData.lvl}
              onChange={handleInputChange}
              inputWidth="38px"
              min="0"
              max={equip.attributes.max_lvl.toString()}
            />
            <BasicFormField
              layout="row"
              label="LB"
              name="limitBreak"
              type="number"
              value={formData.lb}
              onChange={handleInputChange}
              inputWidth="25px"
              min="0"
              max={equip.attributes.max_lb.toString()}
            />
          </BasicFormFieldContainer>
        </Panel>
        <input type='submit' value='Add Equip'/>
      </form>
    </>
  );
}

export default AddEquipForm;
