import React from 'react';
import './UnitAllStatsList.scss';
import DataDisplayRow from '../../../../common/DataDisplayRow'
import Panel from '../../../../common/Panel'

function UnitAllStatsList({ unit }) {
  return (
    <Panel width='100%'>
      <div className='gs-user-unit-all-stats-container'>
        <DataDisplayRow name='Element' value={unit.element} />
        <DataDisplayRow name='Race' value={unit.race} />
        <DataDisplayRow name='Level' value={`${unit.lvl}/${unit.max_lvl}`} />
        <DataDisplayRow name='Limit Break' value={`${unit.lb}/${unit.max_lb}`} />
        <DataDisplayRow name='Luck' value={`${unit.luck}/${unit.max_luck}`} />
        <DataDisplayRow name='Tasmon HP' value={`${unit.tasmon_hp}/${unit.max_tasmon_hp}`} />
        <DataDisplayRow name='Tasmon ATK' value={`${unit.tasmon_atk}/${unit.max_tasmon_atk}`} />
        <DataDisplayRow name='Tasmon DEF' value={`${unit.tasmon_def}/${unit.max_tasmon_def}`} />
        <DataDisplayRow name='Luck Buff HP' value={`${unit.luck_buff_hp}/${unit.max_luck_buff_hp}`} />
        <DataDisplayRow name='Luck Buff ATK' value={`${unit.luck_buff_atk}/${unit.max_luck_buff_atk}`} />
        <DataDisplayRow name='Luck Buff DEF' value={`${unit.luck_buff_def}/${unit.max_luck_buff_def}`} />
        <DataDisplayRow name='Luck Buff Arts' value={`${unit.luck_buff_arts}/${unit.max_luck_buff_arts}`} />
        <DataDisplayRow name='Luck Buff CT' value={`${unit.luck_buff_skill_ct}/${unit.max_luck_buff_skill_ct}`} />
      </div>
    </Panel>
  );
}

export default UnitAllStatsList;
