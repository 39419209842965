import React from 'react';

const LbIconSvg = ({ size = "200", number = "5" }) => (
  <svg width={size} height={size} viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <linearGradient id="rainbowGradient" x1="0%" y1="0%" x2="100%" y2="100%">
        <stop offset="0%" style={{stopColor: "red", stopOpacity: 1}} />
        <stop offset="16%" style={{stopColor: "orange", stopOpacity: 1}} />
        <stop offset="33%" style={{stopColor: "yellow", stopOpacity: 1}} />
        <stop offset="50%" style={{stopColor: "green", stopOpacity: 1}} />
        <stop offset="66%" style={{stopColor: "blue", stopOpacity: 1}} />
        <stop offset="83%" style={{stopColor: "indigo", stopOpacity: 1}} />
        <stop offset="100%" style={{stopColor: "violet", stopOpacity: 1}} />
      </linearGradient>
      <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
        <feOffset dx="4" dy="4"/>
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.5"/>
        </feComponentTransfer>
        <feMerge>
          <feMergeNode/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
    </defs>
    <rect x="50" y="50" width="100" height="100" fill="black" stroke="black" strokeWidth="4" filter="url(#shadow)" />
    <rect x="50" y="50" width="100" height="100" fill="black" stroke="black" strokeWidth="4" transform="rotate(45, 100, 100)" filter="url(#shadow)" />
    <text x="100" y="135" textAnchor="middle" fill="white" fontSize="100" fontFamily="Arial" stroke="black" strokeWidth="0">{number}</text>
  </svg>
);

export default LbIconSvg;
