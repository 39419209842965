import React from 'react';
import Panel from '../common/Panel';

const PrivacyPolicy = () => {
  return (
    <div style={{textAlign: 'center', margin: '0 auto', color: '#fff'}}>
      <Panel width='750px' marginBottom='15px'>
        <h1>Privacy Policy</h1>
        <p>Your privacy is of paramount importance to us at gs-db, an application dedicated to helping users manage their inventory in the game GrandSummoners. Through this Privacy Policy, we aim to inform you about how we handle personal information in connection with our application, which facilitates user sign-in via Discord OAuth.</p>

        <h2>Information We Collect and How We Use It</h2>
        <p><strong>Cookies:</strong> Our application uses cookies to store user preferences, record session states for improved service upon return visits, and customize web content based on browser types or other information provided by users.</p>

        <p><strong>OAuth via Discord:</strong> For a streamlined login process and enhanced security, our application utilizes Discord OAuth for authentication. This grants us access to your Discord profile information, such as your username and email address, based on the permissions you agree to share.</p>

        <p><strong>Personal Information:</strong> The username and email address associated with your Discord account are stored to create and manage your unique user account within our application.</p>

        <h2>Purpose of Processing Data</h2>
        <p>The primary goal of processing your data, including your email address, is to offer you a secure and personalized account experience within our application.</p>

        <h2>Security Measures</h2>
        <p>Reasonable security measures are in place to protect against the loss, misuse, unauthorized access, disclosure, alteration, or destruction of your information. While we strive to use commercially acceptable means to protect your personal information, please note that no method of electronic storage or internet transmission is completely secure.</p>

        <h2>Data Deletion</h2>
        <p>To request the deletion of your data from our records, please send us an email from the address linked to your registered user account, with the subject "Data deletion." We will process your request in accordance with our data retention policies.</p>

        <h2>Changes to This Privacy Policy</h2>
        <p>gs-db reserves the right to update this Privacy Policy at any time. Any changes will be posted on this page, so we encourage you to review it periodically. Your continued use of our application following any changes signifies your acceptance of those changes.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, our practices, or your interactions with our application, please contact us via [your contact information or support channel].</p>

      </Panel>
    </div>
  )
}

export default PrivacyPolicy;
