import React from 'react';
import './ShareBox.scss'
import CopyInput from '../../common/CopyInput';

function ShareBox({ user }) {
  const boxId = user.box_id;
  const domain = window.location.origin;

  const getSharePath = (shareBox) => {
    switch(shareBox.toLowerCase()) {
      case 'unit':
        return `${domain}/unit_box/${boxId}`;
      case 'equip':
        return `${domain}/equip_box/${boxId}`;
      default:
        return '';
    };
  };;

  return (
    <div className='gs-share-box'>
      <h4>Share Box Links</h4>
      <div className='gs-share-unit-box'>
        <span className='gs-share-box-title'>Unit Box</span>
        <CopyInput text={getSharePath('unit')} />
      </div>
      <div className='gs-share-equip-box'>
        <span className='gs-share-box-title'>Equip Box</span>
        <CopyInput text={getSharePath('equip')} />
      </div>
    </div>
  );
};
export default ShareBox;
