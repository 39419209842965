import React, { useState } from 'react';
import './UserBox.scss'
import { UnitBox } from '../Units';
import { EquipBox } from '../Equips';
import { DeleteForeverOutlined } from '@mui/icons-material';

function UserBox() {
  const [currentBox, setCurrentBox] = useState('units');
  const [deleteActive, setDeleteActive] = useState(false);

  const unitBoxActive = (currentBox === 'units')
  const equipBoxActive = (currentBox === 'equips')
  const setBoxMode = (deleteActive === true ? 'delete' : 'show')

  const btnClassNames = (active) => {
    let classes = 'gs-user-box-btn'
    if (active) {
      classes += ' gs-active-box-btn'
    }
    return classes
  }

  const deleteBtnClassNames = (active) => {
    let classes = 'gs-user-box-delete-btn'
    if (active) {
      classes += ' gs-active-box-delete-btn'
    }
    return classes
  }

  const renderContent = () => {
    if (currentBox === 'units') {
      return <UnitBox mode={setBoxMode}/>;
    } else if (currentBox === 'equips') {
      return <EquipBox mode={setBoxMode}/>;
    } else {
      return null;
    }
  };


  return (
    <>
      <div id='gs-user-box'>
        <nav className="gs-user-box-nav">
          <button className={btnClassNames(unitBoxActive)} onClick={() => setCurrentBox('units')}>Units</button>
          <button className={btnClassNames(equipBoxActive)} onClick={() => setCurrentBox('equips')}>Equips</button>
          <button className={deleteBtnClassNames(deleteActive)} onClick={() => setDeleteActive(!deleteActive)}>
            <DeleteForeverOutlined />
          </button>
        </nav>
        {renderContent()}
      </div>
    </>
  );
};

export default UserBox;
