import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { LoginOutlined, LogoutOutlined } from '@mui/icons-material';

function SidebarBottom() {
  const { currentUser, isLoggedIn, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = '/login'
    } catch (error) {
      // Handle logout errors (e.g., display error message)
    }
  };

  const renderContent = () => {
    if (isLoggedIn()) {
      return (
        <div className="gs-sidebar-user-info" >
          <span>{currentUser.username}</span>
          <LogoutOutlined onClick={handleLogout}/>
        </div>
      );
    } else {
      return (
        <div className="gs-sidebar-user-info" >
          <Link to='/login'>
            <span>Login</span>
            <LoginOutlined />
          </Link>
        </div>
      )
    }
  };

  return (
    <div className="gs-sidebar-bottom">
      {renderContent()}
    </div>
  );
}

export default SidebarBottom;
