import React from 'react';
import './UnitPassive.scss';
import Panel from '../../../common/Panel'

function UnitPassive({ passive }) {
  return (
    <Panel width='100%'>
      {passive.description}
    </Panel>
  );
}

export default UnitPassive;
