import React from 'react';
import './AddEquip.scss';
import AddEquipForm from './AddEquipForm/AddEquipForm';

function AddEquip({ equip }) {
  return (
    <section className='gs-add-equip-card'>
      <div className='gs-add-equip-card-heading'>
        <h2>Add Equip</h2>
        <small>{equip.attributes.name}</small>
      </div>
      <img className='gs-equip-artwork' src={equip.attributes.max_artwork_img_url} alt={equip.attributes.name}/>
      <AddEquipForm equip={equip}/>
    </section>
  );
}

export default AddEquip;
