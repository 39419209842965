import React from 'react';
import './UnitAllStatsList.scss';
import DataDisplayRow from '../../../common/DataDisplayRow'
import Panel from '../../../common/Panel'

function UnitAllStatsList({ unit }) {
  return (
    <Panel width='100%' marginBottom='15px'>
      <div className='gs-unit-all-stats-container'>
        <DataDisplayRow name='Rarity' value={unit.rarity} />
        <DataDisplayRow name='Element' value={unit.element} />
        <DataDisplayRow name='Race' value={unit.race} />
        <DataDisplayRow name='Max Level' value={unit.max_lvl} />
        <DataDisplayRow name='Max Limit Break' value={unit.max_lb} />
        <DataDisplayRow name='Max Luck' value={unit.max_luck} />
        <DataDisplayRow name='Max Tasmon HP' value={unit.max_tasmon_hp} />
        <DataDisplayRow name='Max Tasmon ATK' value={unit.max_tasmon_atk} />
        <DataDisplayRow name='Max Tasmon DEF' value={unit.max_tasmon_def} />
        <DataDisplayRow name='Max Luck Buff HP' value={unit.max_luck_buff_hp} />
        <DataDisplayRow name='Max Luck Buff ATK' value={unit.max_luck_buff_atk} />
        <DataDisplayRow name='Max Luck Buff DEF' value={unit.max_luck_buff_def} />
        <DataDisplayRow name='Max Luck Buff Arts' value={unit.max_luck_buff_arts} />
        <DataDisplayRow name='Max Luck Buff CT' value={unit.max_luck_buff_skill_ct} />
      </div>
    </Panel>
  );
}

export default UnitAllStatsList;
