import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;

const useUserUnitShow = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const showUserUnit = (unitId) => {
    const authToken = localStorage.getItem('authToken');
    if (!unitId) {
      setError(new Error('Unit ID is required'));
      setLoading(false);
      return Promise.reject('Unit ID is required');
    }

    return fetch(`${BASE_URL}/user_units/${unitId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setLoading(false);
      return data;
    })
    .catch(error => {
      console.error(`Failed to fetch unit with ID ${unitId}:`, error);
      setError(error);
      setLoading(false);
      return Promise.reject(error);
    });
  };

  return { showUserUnit, loading, error };
};

export default useUserUnitShow;
