import React from 'react';
import './Footer.scss';
import { MailOutline, Copyright } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="gs-footer">
      <hr></hr>
      <div className='gs-footer-content'>
        <div className='gs-footer-section'>
          <div className='gs-footer-fluid'></div>
          <span className='gs-footer-title'>
            This website  is not
            affiliated with nor endorsed by NextNinja.
            All images belong to NextNinja.
          </span>
          <ul>
            <li>
              <MailOutline />
              <a href='mailto: name@email.com'>gs-db@gmail.com</a>
            </li>
            <li>
              <Copyright />
              <span>gs-db.com</span>
            </li>
          </ul>
        </div>
        <div className='gs-footer-section'>
          <span className='gs-footer-section-heading'>Site Information</span>
          <ul>
            <li>
              <Link to="privacy_policy" >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
