import React from 'react';
import './UnitPreview.scss';
import { useAuth } from '../../../context/AuthContext';
import { AddOutlined } from '@mui/icons-material';

function UnitPreview({ unit, addUnitClick, viewUnitClick }) {
  const { isLoggedIn } = useAuth();

  const getBoxShadowClass = (element) => {
    switch(element.toLowerCase()) {
      case 'fire':
        return 'gs-fire-boxshadow';
      case 'water':
        return 'gs-water-boxshadow';
      case 'earth':
        return 'gs-earth-boxshadow';
      case 'light':
        return 'gs-light-boxshadow';
      case 'dark':
        return 'gs-dark-boxshadow';
      default:
        return 'gs-light-boxshadow';
    }
  };

  return (
    <div className="gs-unit-preview">
      <div className="gs-unit-preview-icon">
        <div className={`gs-unit-preview-icon-img ${getBoxShadowClass(unit.attributes.element)}`}>
          <img src={unit.attributes.icon_img_url} onClick={() => viewUnitClick(unit)} alt={unit.attributes.name}/>
          {isLoggedIn() &&
            <div className="gs-unit-preview-add-unit" onClick={() => addUnitClick(unit)}>
              <AddOutlined />
            </div>
          }
        </div>
      </div>
      <div className="gs-unit-preview-name">
        <span>{unit.attributes.name}</span>
      </div>
    </div>
  );
}

export default UnitPreview;
