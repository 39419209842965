import { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;

const useEquipIndex = (refreshTrigger) => {
  const [userEquips, setUserEquips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('Authentication token not found');
      setError(new Error('Authentication token not found'));
      setLoading(false);
      return;
    }

    fetch(`${BASE_URL}/user_equips`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setUserEquips(data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Failed to fetch user equips:', error);
      setError(error);
      setLoading(false);
    });

  }, [refreshTrigger]); // Empty dependency array means this effect runs once on component mount

  return { userEquips, loading, error };
};

export default useEquipIndex;
