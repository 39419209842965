import React, { useState } from 'react';
import { UnitSkills, UnitLore, UnitPassives } from '../../../../units';
import UnitAllStatsList from '../UnitAllStatsList/UnitAllStatsList';
import './UnitMainSection.scss';

function UnitMainSection({ unit }) {
  const [activeView, setActiveView] = useState('Abilities');

  const effectView = (
    <>
      <UnitSkills skills={unit.skills} />
      <UnitPassives passives={unit.passives} />
    </>
  )

  const renderView = () => {
    switch (activeView) {
      case 'Skills':
        return effectView;
      case 'Lore':
        return <UnitLore unit={unit} />;
      case 'Stats':
        return <UnitAllStatsList unit={unit} />;
      default:
        return <UnitSkills skills={unit.skills} />;
    }
  };

  return (
    <section className='gs-user-unit-main-section'>
      <nav className='gs-user-unit-main-section-nav'>
        <button onClick={() => setActiveView('Skills')}>Skills</button>
        <button onClick={() => setActiveView('Lore')}>Lore</button>
        <button onClick={() => setActiveView('Stats')}>Stats</button>
      </nav>
      {renderView()}
    </section>
  );
}

export default UnitMainSection;
