import React from 'react';
import './EquipStatList.scss';

function EquipStatList({ stats }) {
  return (
    <ul className='gs-equip-stats-list'>
      <li className='gs-stat-hp'>
        <span className='gs-stat-title '>HP:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.hp}</span>
        </div>
      </li>
      <li className='gs-stat-atk'>
        <span className='gs-stat-title'>ATK:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.atk}</span>
        </div>
      </li>
      <li className='gs-stat-def'>
        <span className='gs-stat-title'>DEF:</span>
        <div className='gs-stat-container'>
          <span className='gs-stat-data'>{stats.def}</span>
        </div>
      </li>
    </ul>
  );
}

export default EquipStatList;
