import React from 'react';
import './CopyInput.scss';
import { ContentCopyOutlined } from '@mui/icons-material';
import { useFlashMessage } from '../../../context/FlashMessageContext';

const CopyInput = ({ text }) => {
  const { addMessage } = useFlashMessage();
  const handleShareLink = () => {
    navigator.clipboard.writeText(text).then(() => {
      addMessage('Link Copied', 'success');
    }, (err) => {
      addMessage(`Failed to copy link`, 'danger');
    });
  };

  return (
    <div className="gs-copy-container">
      <input type="text" className="gs-copy-input" value={text} disabled={true}/>
      <button className="k" onClick={handleShareLink}>
        <ContentCopyOutlined />
        <span>Copy</span>
      </button>
    </div>
  );
};

export default CopyInput;
