import React from 'react';
import './EquipEffect.scss';
import Panel from '../../../common/Panel'

function EquipEffect({ effect, ct , breakValue }) {
  return (
    <>
      <Panel width="100%">
        <p className='gs-equip-effect-txt'>{effect}</p>
      </Panel>
      <div className='gs-equip-effect-stats-list'>
        <span className='gs-equip-card-ct'>CT: {ct || 0}</span>
        <span className='gs-equip-card-break'>Break: {breakValue || 0}</span>
      </div>
    </>
  );
}

export default EquipEffect;
