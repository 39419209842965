import React from 'react';
import './UnitEquipmentSlots.scss';
import lbOne from '../../../../assets/images/icons/lb/one.png';
import lbTwo from '../../../../assets/images/icons/lb/two.png';
import lbThree from '../../../../assets/images/icons/lb/three.png';
import lbFour from '../../../../assets/images/icons/lb/four.png';
import lbFive from '../../../../assets/images/icons/lb/five.png';
import lbSix from '../../../../assets/images/icons/lb/six.png';
import physicalIcon from '../../../../assets/images/icons/equip_type/physical.png';
import magicIcon from '../../../../assets/images/icons/equip_type/magic.png';
import defenceIcon from '../../../../assets/images/icons/equip_type/defence.png';
import supportIcon from '../../../../assets/images/icons/equip_type/support.png';
import healIcon from '../../../../assets/images/icons/equip_type/heal.png';

function UnitEquipmentSlots({ equipment_slots }) {
  const getEquipIconImg = (equipType) => {
    switch(equipType) {
      case 'physical': return physicalIcon
      case 'magic': return magicIcon
      case 'defence': return defenceIcon
      case 'support': return supportIcon
      case 'heal': return healIcon
      default: return supportIcon;
    }
  }

  const getEquipLbImg = (rarity) => {
    switch(rarity) {
      case 1: return lbOne
      case 2: return lbTwo
      case 3: return lbThree
      case 4: return lbFour
      case 5: return lbFive
      case 6: return lbSix
      default: console.log('missing icon');
    }
  }

  return (
    <ul className='gs-equipment-slot-list'>
      {
        equipment_slots.map((slot, index) => (
          <li key={index}>
            <img className='gs-equipment-slot-equip' src={getEquipIconImg(slot.name)} alt={`${slot.name} icon`} />
            <img className='gs-equipment-slot-lb' src={getEquipLbImg(slot.star)} alt={`LB ${slot.name} icon`} />
          </li>
        ))
      }
    </ul>
  );
}

export default UnitEquipmentSlots;
