import React from 'react';
import { Link } from 'react-router-dom';

function NavItem({ icon: Icon, to, children, onClick }) {
  const content = (
    <>
      <Icon />
      <span>{children}</span>
    </>
  );

  return (
    <li>
      {to ? (
        <Link to={to} className="nav-link">
          {content}
        </Link>
      ) : (
        <div className="nav-link" onClick={onClick ? onClick : null}>
          {content}
        </div>
      )}
    </li>
  );
}

export default NavItem;
