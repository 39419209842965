import React, { useState, useEffect } from 'react';
import './UnitSkills.scss';
import UnitSkill from '../UnitSkill/UnitSkill'

import { KeyboardDoubleArrowLeftOutlined,
         KeyboardDoubleArrowRightOutlined } from '@mui/icons-material';

function UnitSkills({ skills }) {
  const [activeSkillIndex, setActiveSkillIndex] = useState(0);

  useEffect(() => {
    setActiveSkillIndex(0);
  }, [skills]);

  const notFirstSkill = () => {
    return activeSkillIndex > 0;
  }

  const notLastSkill = () => {
    return (activeSkillIndex < skills.length - 1);
  }
  const nextSkill = () => {
    if (notLastSkill()) {
      setActiveSkillIndex(activeSkillIndex + 1);
    }
  }

  const previousSkill = () => {
    if (notFirstSkill()) {
      setActiveSkillIndex(activeSkillIndex - 1);
    }
  }

  const isFirstSkill = () => {
    return activeSkillIndex === 0
  }
  
  const isLastSkill = () => {
    return activeSkillIndex === skills.length - 1
  }

  const skillTypeText = {
    'skill': 'Skill',
    'arts': 'Arts',
    'true_arts': 'True Arts',
    'cross_arts': 'Cross Arts',
    'rev_eq_one_skill': 'Revelation Skill',
    'rev_eq_two_skill': 'Revelation Skill',
    'rev_eq_three_skill': 'Revelation Skill',
    'mega_skill': 'Mega Skill',
    'mega_arts': 'Mega Arts',
    'phantom_bullet': 'Phantom Bullet',
    'super_arts': 'Super Arts'
  }

  const getSkillTypeText = (key) => {
    return skillTypeText[key] || 'Unknown Skill Type';
  }

  if (!skills || skills.length === 0) return <div>No skills available</div>;

  return (
    <div className='gs-skill-box'>
      <div className='gs-skill-heading'>
        <h3 className="gs-skill-heading-text">{getSkillTypeText(skills[activeSkillIndex].skill_type)}</h3>
        <div className='gs-skill-heading-actions'>
          <KeyboardDoubleArrowLeftOutlined className={isFirstSkill() ? 'disabled' : ''} onClick={previousSkill}/>
          <KeyboardDoubleArrowRightOutlined className={isLastSkill() ? 'disabled' : ''} onClick={nextSkill}/>
        </div>
      </div>
      <UnitSkill skill={skills[activeSkillIndex]} />
    </div>
  );
}

export default UnitSkills;
