import React, { createContext, useContext, useState } from 'react';

const FlashMessageContext = createContext();

export const FlashMessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  const addMessage = (text, type) => {
    const newMessage = { text, type, id: new Date().getTime() };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setTimeout(() => {
      removeMessage(newMessage.id);
    }, 1000); // Remove the message after 3 seconds
  };

  const removeMessage = (id) => {
    setMessages((prevMessages) =>
      prevMessages.filter((message) => message.id !== id)
    );
  };

  return (
    <FlashMessageContext.Provider value={{ messages, addMessage, removeMessage }}>
      {children}
    </FlashMessageContext.Provider>
  );
};

export const useFlashMessage = () => useContext(FlashMessageContext);
