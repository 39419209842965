import { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const useUnitBox = (box_id) => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/unit_box/${box_id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': API_KEY,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setUnits(data);
      } catch (error) {
        console.error('Failed to fetch box:', error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [box_id]);

  return { units, loading, error };
};

export default useUnitBox;
