import React from 'react';
import { Outlet } from 'react-router-dom';
import './DefaultLayout.scss';
import { SidePanelProvider } from '../../../context/SidePanelContext';
import SideNav from '../SideNav'
import SidePanel from '../../common/SidePanel'
import Footer from '../Footer/Footer';
import FlashMessagesDisplay from '../../common/FlashMessagesDisplay'

function DefaultLayout() {
  return (
    <SidePanelProvider>
      <div className="gs-default-layout">
        <FlashMessagesDisplay />
        <SideNav />
        <main className="gs-default-content">
          <section className='gs-main-section'>
            <Outlet /> {/* This replaces {children} and automatically renders the correct component based on the route */}
          </section>
          <Footer />
        </main>
        <SidePanel />
      </div>
    </SidePanelProvider>
  );
}


export default DefaultLayout;
