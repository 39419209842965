import React, { useEffect } from 'react';
import { useSidePanel } from '../../../context/SidePanelContext';
import { CloseOutlined } from '@mui/icons-material';
import './SidePanel.scss';

const SidePanel = () => {
  const { isOpen, content, closeSidePanel } = useSidePanel();
  const sidePanelClasses = isOpen ? 'gs-sidepanel-open' : 'gs-sidepanel'; // Add CSS classes

  useEffect(() => {
    if (isOpen) {
      const element = document.querySelector('.gs-sidepanel-content').firstChild;
      if (element) {
        element.scrollTop = 0;
      }
    }
  });

  return (
    <aside className={sidePanelClasses}>
      <CloseOutlined className="close-icon" onClick={closeSidePanel}/>
      <div className="gs-sidepanel-content">
        {content}
      </div>
    </aside>
  );
};

export default SidePanel;
