import React from 'react';
import './UnitSkill.scss'; // Assuming you'll have separate CSS for this component

function UnitSkill({ skill }) {
  return (
    <div className="gs-skill-card">
      <div className='gs-skill-card-heading'>
        <div className="gs-skill-name">{skill.name || 'Name Missing'}</div>
        {skill.ct && <div className="gs-skill-ct">CT: {skill.ct}s</div>}
        {skill.break > 0 && <div className="gs-skill-break" title='Break Value'>{skill.break}</div>}
      </div>
      <div className="gs-skill-details">{skill.effect}</div>
    </div>
  );
}

export default UnitSkill;
