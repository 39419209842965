import React from 'react';
import './UnitLore.scss';
import Panel from '../../../common/Panel'
function UnitLore({ unit }) {
  return (
    <Panel width="100%" marginBottom='15px'>
      <p className='gs-lore-txt'>{unit.lore}</p>
    </Panel>
  );
}

export default UnitLore;
