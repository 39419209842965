import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import './SideNav.scss';
import {
  DashboardOutlined,
  PortraitOutlined,
  ColorizeOutlined,
  Inventory2Outlined,
  ShareOutlined,
} from '@mui/icons-material';
import NavItem from './NavItem';
import SideNavBottom from './SideNavBottom';
import DialogBox from '../../common/DialogBox';
import { ShareBox } from '../../user';

const SideNav = () => {
  const { currentUser, isLoggedIn } = useAuth();
  const [showShareBoxDialog, setShowShareBoxDialog] = useState(false);

  const toggleShareDialog = () => {
    setShowShareBoxDialog(!showShareBoxDialog);
  };

  return (
    <aside className='gs-sidebar'>
      <h1 className='gs-sidebar-title'>GS DB</h1>
      <nav>
        <ul>
          <NavItem icon={DashboardOutlined} to='/'>
            Home
          </NavItem>
          <NavItem icon={PortraitOutlined} to='/units'>
            Units
          </NavItem>
          <NavItem icon={ColorizeOutlined} to='/equips'>
            Equips
          </NavItem>
          {isLoggedIn() && (
            <NavItem icon={Inventory2Outlined} to='/box'>
              My Box
            </NavItem>
          )}
          {isLoggedIn() && (
            <NavItem icon={ShareOutlined} to='' onClick={toggleShareDialog}>
              Share
            </NavItem>
          )}
        </ul>
      </nav>
      <SideNavBottom />

      {isLoggedIn() &&
        <DialogBox isOpen={showShareBoxDialog} closeModal={() => setShowShareBoxDialog(false)}>
          <ShareBox user={currentUser}/>
        </DialogBox>
      }
    </aside>
  );
}

export default SideNav;
