import React from 'react';
import './EquipCard.scss';
import EquipCardHeading from './EquipCardHeading/EquipCardHeading'
import EquipCardRarityStars from './EquipCardRarityStars/EquipCardRarityStars'
import EquipStatList from './EquipStatList/EquipStatList'
import EquipMainSection from './EquipMainSection/EquipMainSection'
import EquipUnits from './EquipUnits/EquipUnits'

function EquipCard({ equip }) {
  const equipData = equip.attributes
  const equipStats = {
    hp: equipData.hp,
    atk: equipData.atk,
    def: equipData.defence
  }

  return (
    <section className='gs-equip-card'>
      <EquipCardHeading name={equipData.name} equipment_type={equipData.equipment_type} />
      <img className='gs-equip-artwork' src={equipData.max_artwork_img_url} alt={equipData.name}/>
      <EquipCardRarityStars rarity={equipData.rarity} />
      <EquipStatList stats={equipStats} />
      <EquipMainSection equip={equipData} />
      <EquipUnits units={equipData.units} />
    </section>
  );
}

export default EquipCard;
