import { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const useUnitsIndex = () => {
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log(BASE_URL)
    fetch(`${BASE_URL}/units`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setUnits(data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Failed to fetch units:', error);
      setError(error);
      setLoading(false);
    });

  }, []);

  return { units, loading, error };
};

export default useUnitsIndex;
