import React from 'react';
import './EquipPreview.scss';
import { useAuth } from '../../../context/AuthContext';
import { AddOutlined } from '@mui/icons-material';

function EquipPreview({ equip, addEquipClick, viewEquipClick }) {
  const { isLoggedIn } = useAuth();

  const getBoxShadowClass = (equipment_type) => {
    switch(equipment_type.toLowerCase()) {
      case 'physical':
        return 'gs-physical-boxshadow';
      case 'magic':
        return 'gs-magic-boxshadow';
      case 'defence':
        return 'gs-defence-boxshadow';
      case 'support':
        return 'gs-support-boxshadow';
      case 'heal':
        return 'gs-heal-boxshadow';
      default:
        return 'gs-light-boxshadow';
    }
  };

  return (
    <div className="gs-equip-preview">
      <div className="gs-equip-preview-icon">
        <div className={`gs-equip-preview-icon-img ${getBoxShadowClass(equip.attributes.equipment_type)}`}>
          <img src={equip.attributes.max_icon_img_url} onClick={() => viewEquipClick(equip)} alt={equip.attributes.name}/>
          {isLoggedIn() &&
            <div className="gs-equip-preview-add-equip" onClick={() => addEquipClick(equip)}>
              <AddOutlined />
            </div>
          }
        </div>
      </div>
      <div className="gs-equip-preview-name">
        <span>{equip.attributes.name}</span>
      </div>
    </div>
  );
}

export default EquipPreview;
