import React from 'react';
import './EquipUnits.scss';
import { UnitCard } from '../../../units';
import useUnitShow from '../../../../hooks/units/useUnitShow';
import { useSidePanel } from '../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../context/FlashMessageContext';

function EquipUnits({ units }) {
  const { showUnit } = useUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewUnit = (unitId) => {
    showUnit(unitId)
      .then(unit => {
        openSidePanel(<UnitCard unit={unit.data} />);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', error);
      });
  };

  if (units.length === 0) return '';

  return (
    <section className='gs-equip-units'>
      <span className='gs-equip-units-title'>Related Units</span>
      {
        units.map((unit, index) => (
          <div className='gs-equip-unit' key={index}>
            <img src={unit.icon_img_url} onClick={() => viewUnit(unit.id)} alt={unit.name}/>
            <span>{unit.name}</span>
          </div>
        ))
      }
    </section>
  );
}

export default EquipUnits;
