import React from 'react';
import { useAuth } from '../../context/AuthContext';

function Home() {
  const { currentUser, isLoggedIn } = useAuth();

  return (
    <div>
      <h2>Home</h2>
      {isLoggedIn() ? (
        <p>Welcome, {currentUser?.username}!</p>
      ) : (
        <p>Please log in.</p>
      )}
    </div>
  )
}

export default Home;
