import React from 'react';
import { EquipsList, EquipCard } from '../equips';
import { AddEquip } from '../user/Equips';
import { useSidePanel } from '../../context/SidePanelContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import useEquipsIndex from '../../hooks/equips/useEquipsIndex';
import useEquipShow from '../../hooks/equips/useEquipShow';

function Equips() {
  const { equips, loading: indexLoading, error: indexError } = useEquipsIndex();
  const { showEquip, error: showError } = useEquipShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewEquipClick = (previewEquip) => {
    showEquip(previewEquip.id)
      .then(equip => {
        openSidePanel(<EquipCard equip={equip.data} />);
      })
      .catch(error => {
        addMessage('Failed to load equip', 'danger');
        console.error('Error:', showError);
      });
  };

  const addEquipClick = (previewEquip) => {
    showEquip(previewEquip.id)
      .then(equip => {
        openSidePanel(<AddEquip equip={equip.data} />);
      })
      .catch(error => {
        addMessage('Failed to load equip', 'danger');
        console.error('Error:', showError);
      });
  }

  if (indexLoading) return <p>Loading equips...</p>;
  if (indexError) return <p>Error loading equips: {indexError.message}</p>;

  return (
   <EquipsList equips={equips.data} addEquipClick={addEquipClick} viewEquipClick={viewEquipClick} />
 );
}

export default Equips;
