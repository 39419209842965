import React from 'react';
import './EquipPassives.scss';
import EquipPassive from '../EquipPassive/EquipPassive'

function EquipPassives({ passives }) {
  return (
    <>
      <h3 className='gs-equip-passives-title'>Passives</h3>
      <section className='gs-equip-passives-container'>
        {
          passives.map((passive, index) => (
            <EquipPassive key={index} passive={passive} />
          ))
        }
      </section>
    </>
  );
}

export default EquipPassives;
