import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function DiscordAuth() {
  const location = useLocation();

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('token');
    if (code) {
      console.log(code)
      localStorage.setItem('authToken', code);
    }
  }, [location]);

  return (
    <div>
      Authenticating...
    </div>
  );
}

export default DiscordAuth;
