import React from 'react';
import { Outlet } from 'react-router-dom';
import { SidePanelProvider } from '../../../context/SidePanelContext';
import SidePanel from '../../common/SidePanel'
import './PublicLayout.scss';

function PublicLayout() {
  return (
    <SidePanelProvider>
      <div className="gs-public-layout">
        <main className="gs-public-content">
          <Outlet /> {/* This replaces {children} and automatically renders the correct component based on the route */}
        </main>
        <SidePanel />
      </div>
    </SidePanelProvider>
  );
}

export default PublicLayout;
