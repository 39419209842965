import React from 'react';
import '../../styles/pages/EquipBox.scss';
import { useParams } from 'react-router-dom';
import { EquipPreview, EquipCard } from '../user/Equips';
import { useSidePanel } from '../../context/SidePanelContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import useEquipBox from '../../hooks/equipBox/useEquipBox';
import useUserEquipShow from '../../hooks/userEquips/useUserEquipShow';

function EquipBox() {
  const { box_id } = useParams();
  const { equips, loading: boxLoading, error: boxError } = useEquipBox(box_id);
  const { showUserEquip, error: showError } = useUserEquipShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewEquipClick = (previewEquip) => {
    showUserEquip(previewEquip.id)
      .then(equip => {
        openSidePanel(<EquipCard equip={equip.data} />);
      })
      .catch(error => {
        addMessage('Failed to load equip', 'danger');
        console.error('Error:', showError);
      });
  };

  if (boxLoading) return <p>Loading equips...</p>;
  if (boxError) return <p>Error loading equips: {boxError.message}</p>;

  return (
    <div id='gs-public-user-equip-box'>
      {equips.data.map((equip) => (
        <EquipPreview
          key={equip.id}
          equip={equip}
          viewEquipClick={viewEquipClick}
          refreshEquips={''}
          mode={'show'}
        />
      ))}
    </div>
  );
}

export default EquipBox;
