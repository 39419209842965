import React from 'react';
import './UnitCard.scss';
import { UnitEquipmentSlots, UnitTrueWeapons } from '../../../units';
import UnitCardHeading from './UnitCardHeading/UnitCardHeading'
import UnitStatList from './UnitStatList/UnitStatList'
import UnitMainSection from './UnitMainSection/UnitMainSection'

function UnitCard({ unit }) {
  const unitData = unit.attributes
  const unitStats = {
    hp: unitData.hp,
    atk: unitData.atk,
    def: unitData.defence,
  }
  const unitTasStats = {
    hp: unitData.tasmon_hp,
    atk: unitData.tasmon_atk,
    def: unitData.tasmon_def,
  }
  const unitLuckStats = {
    hp: unitData.luck_buff_hp,
    atk: unitData.luck_buff_atk,
    def: unitData.luck_buff_def,
  }

  return (
    <section className='gs-user-unit-card'>
      <UnitCardHeading name={unitData.name} lvl={unitData.lvl} lb={unitData.lb} luck={unitData.luck} />
      <img className='gs-unit-artwork' src={unitData.artwork_img_url} alt={unitData.name}/>
      <UnitEquipmentSlots equipment_slots={unitData.equipment_slots} />
      <UnitStatList stats={unitStats} tasStats={unitTasStats} luckStats={unitLuckStats} />
      <UnitMainSection unit={unitData}/>
      <UnitTrueWeapons trueWeapons={unitData.true_weapons}/>
    </section>

  );
}

export default UnitCard;
// <UnitPassives passives={unitData.passives} />
