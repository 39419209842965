import React from 'react';
import BasicFormField from '../../../../../common/BasicFormField';
import BasicFormFieldContainer from '../../../../../common/BasicFormFieldContainer';
import Panel from '../../../../../common/Panel';

function UnitBasicInfoPanel({ formData, handleInputChange, unit }) {
  return (
    <Panel>
      <BasicFormFieldContainer>
        <BasicFormField
          layout="row"
          label="LB"
          name="limitBreak"
          type="number"
          value={formData.lb}
          onChange={handleInputChange}
          inputWidth="25px"
          min="0"
          max={unit.attributes.max_lb.toString()}
        />
        <BasicFormField
          layout="row"
          label="LVL"
          name="level"
          type="number"
          value={formData.lvl}
          onChange={handleInputChange}
          inputWidth="38px"
          min="1"
          max={unit.attributes.max_lvl.toString()}
        />
        <BasicFormField
          layout="row"
          label="Luck"
          name="luck"
          type="number"
          value={formData.luck}
          onChange={handleInputChange}
          inputWidth="40px"
          min="1"
          max={unit.attributes.max_luck.toString()}
        />
      </BasicFormFieldContainer>
    </Panel>
  );
}

export default UnitBasicInfoPanel;
