import React from 'react';
import './EquipLore.scss';
import Panel from '../../../common/Panel'

function EquipLore({ lore }) {
  return (
    <Panel width="100%">
      <p className='gs-equip-lore-txt'>{lore || 'Missing Lore'}</p>
    </Panel>
  );
}

export default EquipLore;
