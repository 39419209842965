import React, { useState } from 'react';
import { EquipEffect, EquipPassives, EquipLore } from '../../../../equips';
import EquipAllStatsList from '../EquipAllStatsList/EquipAllStatsList';
import './EquipMainSection.scss';

function EquipMainSection({ equip }) {
  const [activeView, setActiveView] = useState('Effect');

  const effectView = (
    <>
      <EquipEffect effect={equip.effect} />
      <EquipPassives passives={equip.passives} />
    </>
  )

  const renderView = () => {
    switch (activeView) {
      case 'Effect':
        return effectView;
      case 'Lore':
        return <EquipLore lore={equip.lore} />;
      case 'Stats':
        return <EquipAllStatsList equip={equip} />;
      default:
      return effectView;
    }
  };

  return (
    <section className='gs-user-equip-main-section'>
      <nav className='gs-user-equip-main-section-nav'>
        <button onClick={() => setActiveView('Effect')}>Effect</button>
        <button onClick={() => setActiveView('Lore')}>Lore</button>
        <button onClick={() => setActiveView('Stats')}>Stats</button>
      </nav>
      {renderView()}
    </section>
  );
}

export default EquipMainSection;
