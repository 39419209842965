import React from 'react';
import BasicFormField from '../../../../../common/BasicFormField';
import BasicFormFieldContainer from '../../../../../common/BasicFormFieldContainer';
import Panel from '../../../../../common/Panel';

function LuckBuffStatsPanel({ formData, handleInputChange, unit }) {
  return (
    <Panel>
      <h3>Luck Buff Stats</h3>
      <BasicFormFieldContainer>
        <BasicFormField
          layout="column"
          label="HP"
          name="luckBuffHP"
          type="number"
          value={formData.luck_buff_hp}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_luck_buff_hp.toString()}
        />
        <BasicFormField
          layout="column"
          label="ATK"
          name="luckBuffATK"
          type="number"
          value={formData.luck_buff_atk}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_luck_buff_atk.toString()}
        />
        <BasicFormField
          layout="column"
          label="DEF"
          name="luckBuffDEF"
          type="number"
          value={formData.luck_buff_def}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_luck_buff_def.toString()}
        />
        <BasicFormField
          layout="column"
          label="Arts Up"
          name="luckBuffArts"
          type="number"
          value={formData.luck_buff_arts}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_luck_buff_arts.toString()}
        />
        <BasicFormField
          layout="column"
          label="Skill CT Up"
          name="luckBuffSpeed"
          type="number"
          value={formData.luck_buff_skill_ct}
          onChange={handleInputChange}
          inputWidth="260px"
          min="0"
          max={unit.attributes.max_luck_buff_skill_ct.toString()}
        />
      </BasicFormFieldContainer>
    </Panel>
  );
}

export default LuckBuffStatsPanel;
