import { useState, useEffect } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const useEquipsIndex = () => {
  const [equips, setEquips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`${BASE_URL}/equips`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setEquips(data);
      setLoading(false);
    })
    .catch(error => {
      console.error('Failed to fetch equips:', error);
      setError(error);
      setLoading(false);
    });

  }, []);

  return { equips, loading, error };
};

export default useEquipsIndex;
