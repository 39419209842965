import React from 'react';
import '../styles/App.scss';
import { Routes, Route } from 'react-router-dom';
import { DefaultLayout, PublicLayout } from './layout';
import Home from './pages/Home';
import Login from './pages/Login';
import DiscordAuth from './pages/DiscordAuth';
import Units from './pages/Units';
import Equips from './pages/Equips';
import Box from './pages/Box';
import UnitBox from './pages/UnitBox';
import EquipBox from './pages/EquipBox';
import PrivacyPolicy from './pages/PrivacyPolicy';



function App() {
  return (
    <Routes>
      <Route path="/" element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="units" element={<Units />} />
        <Route path="equips" element={<Equips />} />
        <Route path="box" element={<Box />} />
        <Route path="auth/callback" element={<DiscordAuth />} />
        <Route path="privacy_policy" element={<PrivacyPolicy />} />
      </Route>
      {/* New routes for unit_box and equip_box outside of the Layout */}
      <Route path="/" element={<PublicLayout />}>
        <Route path="/unit_box/:box_id" element={<UnitBox />} />
        <Route path="/equip_box/:box_id" element={<EquipBox />} />
      </Route>
    </Routes>
  );
}

export default App;
