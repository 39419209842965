import React from 'react';
import './AddPlaceholder.scss';

const AddPlaceholder = ({ styles }) => {
  return (
    <div className='add-placeholder' style={styles}></div>
  );
}

export default AddPlaceholder;
