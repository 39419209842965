import React from 'react';
import './UnitPreview.scss';
import LbIconSvg from '../../../common/LbIconSvg';
import DeleteIcon from './DeleteIcon/DeleteIcon';

function UnitPreview({ unit, viewUnitClick, refreshUnits, mode }) {
  const isShowMode = (mode === 'show')

  const renderModeIcon = () => {
    if (isShowMode) {
      return (
        <div className='gs-user-unit-lb'>
          <LbIconSvg size={100} number={unit.attributes.lb} />
        </div>
      );
    } else {
      return <DeleteIcon unit={unit} refreshUnits={refreshUnits} />;
    }
  }

  const getBoxShadowClass = (element) => {
    switch(element.toLowerCase()) {
      case 'fire':
        return 'gs-user-fire-boxshadow';
      case 'water':
        return 'gs-user-water-boxshadow';
      case 'earth':
        return 'gs-user-earth-boxshadow';
      case 'light':
        return 'gs-user-light-boxshadow';
      case 'dark':
        return 'gs-user-dark-boxshadow';
      default:
        return 'gs-user-light-boxshadow';
    }
  };

  return (
    <div className="gs-user-unit-preview">
      <div className="gs-user-unit-preview-icon">
        {renderModeIcon()}
        <div className={`gs-user-unit-preview-icon-img ${getBoxShadowClass(unit.attributes.element)}`}>
          <img src={unit.attributes.icon_img_url} onClick={() => viewUnitClick(unit)} alt={unit.attributes.name}/>
        </div>
      </div>
      <div className="gs-user-unit-preview-name">
        <span>{unit.attributes.name}</span>
      </div>
    </div>
  );
}

export default UnitPreview;
