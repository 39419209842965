import React from 'react';
import './EquipAllStatsList.scss';
import DataDisplayRow from '../../../common/DataDisplayRow'
import Panel from '../../../common/Panel'

function EquipAllStatsList({ equip }) {
  return (
    <Panel width='100%'>
      <div className='gs-equip-all-stats-container'>
        <DataDisplayRow name='Equip Type' value={equip.equipment_type} />
        <DataDisplayRow name='Rarity' value={equip.rarity} />
        <DataDisplayRow name='Max Level' value={equip.max_lvl} />
        <DataDisplayRow name='Max Limit Break' value={equip.max_lb} />
        <DataDisplayRow name='CT' value={equip.ct} />
        <DataDisplayRow name='Break' value={equip.break} />
        <DataDisplayRow name='Damage' value={equip.damage_multipler || 0} />
        <DataDisplayRow name='Damage Element' value={equip.damage_element || 'none'} />
        <DataDisplayRow name='Damage Type' value={equip.damage_type || 'none'} />
        <DataDisplayRow name='EV' value={equip.ev ? 'Yes' : 'No'} />
      </div>
    </Panel>
  );
}

export default EquipAllStatsList;
