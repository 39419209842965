import React from 'react';
import './EquipCardRarityStars.scss';
import { StarOutlined } from '@mui/icons-material';


function EquipCardRarityStars({ rarity }) {
  const stars = Array.from({ length: rarity }, () => <StarOutlined />);

  return (
    <div className='gs-equip-card-rarity-container'>
      {stars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}
    </div>
  );
}

export default EquipCardRarityStars;
