import React from 'react';
import './BasicFormComponent.scss';

function BasicFormField({ layout = 'column', label, name, type = 'text', value, onChange, inputWidth, min, max }) {
  return (
    <div className={`gs-basic-form-field gs-form-${layout}`}>
      <label htmlFor={name} className="gs-form-label">{label}</label>
      <input
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className="gs-form-input"
        style={{ width: inputWidth ? inputWidth : '100%' }}
        min={min}
        max={max}
      />
    </div>
  );
}

export default BasicFormField;
