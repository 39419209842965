import React, { useState, useEffect, useMemo } from 'react';
import './AddUnitForm.scss';
import MinMaxControls from './MinMaxControls/MinMaxControls'
import UnitBasicInfoPanel from './UnitBasicInfoPanel/UnitBasicInfoPanel'
import TasmonStatsPanel from './TasmonStatsPanel/TasmonStatsPanel'
import LuckBuffStatsPanel from './LuckBuffStatsPanel/LuckBuffStatsPanel'
import { useSidePanel } from '../../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../../context/FlashMessageContext';

const BASE_URL = process.env.REACT_APP_API_URL;

function AddUnitForm({ unit }) {
  const initialFormData = useMemo(() => ({
      unit_id: unit.id,
      lvl: '1',
      lb: '0',
      luck: '5',
      tasmon_hp: '0',
      tasmon_atk: '0',
      tasmon_def: '0',
      luck_buff_hp: '0',
      luck_buff_atk: '0',
      luck_buff_def: '0',
      luck_buff_arts: '0',
      luck_buff_skill_ct: '0',
    }), [unit.id]);

  const [activeView, setActiveView] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const { closeSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  useEffect(() => {
    setFormData(initialFormData);
  }, [unit.id, initialFormData])

  const mapInputNameToStateKey = (inputName) => {
    const mapping = {
      level: 'lvl',
      limitBreak: 'lb',
      tasmonHP: 'tasmon_hp',
      tasmonATK: 'tasmon_atk',
      tasmonDEF: 'tasmon_def',
      luckBuffHP: 'luck_buff_hp',
      luckBuffATK: 'luck_buff_atk',
      luckBuffDEF: 'luck_buff_def',
      luckBuffArts: 'luck_buff_arts',
      luckBuffSpeed: 'luck_buff_skill_ct',
    };
    return mapping[inputName] || inputName;
  };

  const clampValue = (value, min, max) => {
    const minValue = Number(min);
    const maxValue = Number(max);
    const numericValue = Number(value);
    if (numericValue < minValue) {
      return minValue.toString();
    } else if (numericValue > maxValue) {
      return maxValue.toString();
    }
    return value;
  };

  const handleInputChange = (event) => {
    const { name, value, min, max } = event.target;
    const stateKey = mapInputNameToStateKey(name);
    const clampedValue = clampValue(value, min, max);
    setFormData(prevFormData => ({
      ...prevFormData,
      [stateKey]: clampedValue
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(BASE_URL)
    try {
      const response = await fetch(`${BASE_URL}/user_units`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ user_unit: formData })
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      await response.json();
      addMessage(`Succesfully added ${unit.attributes.name}`, 'success');
      setFormData(initialFormData)
      closeSidePanel()
    } catch (error) {
      addMessage(`Failed to add ${unit.attributes.name}`, 'danger');
      console.error('Error:', error);
      // Handle errors (e.g., showing an error message to the user)
    }
  };

  const renderView = () => {
    const base = (
      <>
        <UnitBasicInfoPanel formData={formData} handleInputChange={handleInputChange} unit={unit} />
        <TasmonStatsPanel formData={formData} handleInputChange={handleInputChange} unit={unit} />
      </>
    );
    switch (activeView) {
      case 'Base':
        return base;
      case 'Luck':
        return <LuckBuffStatsPanel formData={formData} handleInputChange={handleInputChange} unit={unit} />;
      default:
        return base;
    }
  };

  return (
    <>
      <MinMaxControls setFormData={setFormData} unit={unit} />
      <nav className='gs-add-unit-form-nav'>
        <button onClick={() => setActiveView('Base')}>Base</button>
        <button onClick={() => setActiveView('Luck')}>Luck</button>
      </nav>
      <form id={`unit-${unit.id}`} className='gs-add-unit-form' onSubmit={handleSubmit} >
        {renderView()}
        <input type='submit' value='Add Unit'/>
      </form>
    </>
  );
}

export default AddUnitForm;
