import React from 'react';
import './UnitPassives.scss';
import UnitPassive from '../UnitPassive/UnitPassive'

function UnitPassives({ passives }) {
  return (
    <>
      <span className='gs-unit-pasives-title'>Passives</span>
      <section className='gs-unit-passives-container'>
        {
          passives.map((passive, index) => (
            <UnitPassive passive={passive} key={index} />
          ))
        }
      </section>
    </>
  );
}

export default UnitPassives;
