import React from 'react';

function Login() {
  const generateState = () => {
    const randomString = Math.random().toString(36).substring(2, 15);
    sessionStorage.setItem('oauthState', randomString);
    return randomString;
  };

  const handleLogin = () => {
    const state = generateState();
    const discordOauthCallback = process.env.REACT_APP_DISCORD_OAUTH_CALLBACK;
    const CLIENT_ID = process.env.REACT_APP_DISCORD_CLIENT_ID;
    const REDIRECT_URI = encodeURIComponent(`${discordOauthCallback}`);
    const discordAuthUrl = `https://discord.com/api/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=identify%20email&state=${state}`;
    window.location.href = discordAuthUrl;
  };

  return (
    <div>
      <button onClick={handleLogin}>Log in with Discord</button>
    </div>
  );
}

export default Login;
