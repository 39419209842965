import React from 'react';
import { UnitsList, UnitCard } from '../units';
import { AddUnit } from '../user/Units';
import AddPlaceholder from '../common/AddPlaceholder';
import { useSidePanel } from '../../context/SidePanelContext';
import { useFlashMessage } from '../../context/FlashMessageContext';
import useUnitsIndex from '../../hooks/units/useUnitsIndex';
import useUnitShow from '../../hooks/units/useUnitShow';

function Units() {
  const { units, loading: indexLoading, error: indexError } = useUnitsIndex();
  const { showUnit, error: showError } = useUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewUnitClick = (previewUnit) => {
    showUnit(previewUnit.id)
      .then(unit => {
        openSidePanel(<UnitCard unit={unit.data} />);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', showError);
      });
  };

  const addUnitClick = (previewUnit) => {
    showUnit(previewUnit.id)
      .then(unit => {
        openSidePanel(<AddUnit unit={unit.data} />);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', showError);
      });
  }

  if (indexLoading) return <p>Loading units...</p>;
  if (indexError) return <p>Error loading units: {indexError.message}</p>;

  return (
    <>
      <AddPlaceholder styles={{width: '100%', height: '150px', minWidth: '297px'}}/>
      <UnitsList units={units.data} addUnitClick={addUnitClick} viewUnitClick={viewUnitClick}/>
      <AddPlaceholder styles={{flexGrow: 1, height: 'auto', minHeight: '150px', minWidth: '297px'}}/>
    </>
  );
}

export default Units;
