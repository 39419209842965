import React from 'react';
import EquipPreview from '../EquipPreview/EquipPreview'
import './EquipsList.scss';

function EquipsList({ equips, addEquipClick, viewEquipClick }) {
  return (
    <div id='gs-equip-list'>
      {equips.map((equip) => (
        <EquipPreview key={equip.id} equip={equip} addEquipClick={addEquipClick} viewEquipClick={viewEquipClick} />
      ))}
    </div>
  );
}

export default EquipsList;
