import React from 'react';
import './UnitCard.scss';
import UnitVersions from './UnitVersions/UnitVersions'
import UnitEquipmentSlots from './UnitEquipmentSlots/UnitEquipmentSlots'
import UnitStatList from './UnitStatList/UnitStatList'
import UnitMainSection from './UnitMainSection/UnitMainSection'
import UnitTrueWeapons from './UnitTrueWeapons/UnitTrueWeapons'

function UnitCard({ unit, unitVersions }) {
  const unitData = unit.attributes
  const unitStats = {
    hp: unitData.hp,
    atk: unitData.atk,
    def: unitData.defence
  }
  const unitTasStats = {
    hp: unitData.max_tasmon_hp,
    atk: unitData.max_tasmon_atk,
    def: unitData.max_tasmon_def
  }

  return (
    <section className='gs-unit-card'>
      <h2 className=''>{unitData.name}</h2>
      <UnitVersions unitVersions={unitVersions || unitData.versions} />
      <img className='gs-unit-artwork' src={unitData.artwork_img_url} alt={unitData.name}/>
      <UnitEquipmentSlots equipment_slots={unitData.equipment_slots} />
      <UnitStatList stats={unitStats} tasStats={unitTasStats} />
      <UnitMainSection unit={unitData}/>
      <UnitTrueWeapons trueWeapons={unitData.true_weapons}/>
    </section>

  );
}

export default UnitCard;

// <ul>
//   <li>{unitData.element}</li>
//   <li>{unitData.race}</li>
// </ul>
