import React from 'react';
import './DialogBox.scss'; // Separate SCSS file for dialog box styles
import { CloseOutlined } from '@mui/icons-material';


function DialogBox({ isOpen, closeModal, children }) {
  if (!isOpen) return null;

  return (
    <div className="gs-dialog-box" onClick={closeModal}>
      <div className="gs-dialog-box-content" onClick={(e) => e.stopPropagation()}>
        <CloseOutlined className="gs-dialog-box-close" onClick={closeModal}/>
        {children}
      </div>
    </div>
  );
}

export default DialogBox;
