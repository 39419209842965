import React from 'react';
import './UnitTrueWeapons.scss';
import { EquipCard } from '../../../equips';
import useEquipShow from '../../../../hooks/equips/useEquipShow';
import { useSidePanel } from '../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../context/FlashMessageContext';

function UnitTrueWeapons({ trueWeapons }) {
  const { showEquip } = useEquipShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewEquip = (equipId) => {
    showEquip(equipId)
      .then(equip => {
        openSidePanel(<EquipCard equip={equip.data} />);
      })
      .catch(error => {
        addMessage('Failed to load equip', 'danger');
        console.error('Error:', error);
      });
  };

  if (trueWeapons.length === 0) return '';

  return (
    <section className='gs-unit-true-weapons'>
      <span className='gs-unit-true-weapons-title'>True Weapons</span>
      {
        trueWeapons.map((trueWeapon, index) => (
          <div className='gs-unit-true-weapon' key={index}>
            <img src={trueWeapon.max_icon_img_url} onClick={() => viewEquip(trueWeapon.id)} alt={trueWeapon.name}/>
            <span>{trueWeapon.name}</span>
          </div>
        ))
      }
    </section>
  );
}

export default UnitTrueWeapons;
