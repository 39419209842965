import React from 'react';
import AddUnitForm from './AddUnitForm/AddUnitForm';
import AddUnitVersions from './AddUnitVersions/AddUnitVersions';
import './AddUnit.scss';

function AddUnit({ unit, unitVersions }) {
  return (
    <section className='gs-add-unit-card'>
      <div className='gs-add-unit-card-heading'>
        <h2>Add Unit</h2>
        <small>{unit.attributes.name}</small>
      </div>
      <AddUnitVersions unitVersions={unitVersions || unit.attributes.versions} />
      <img className='gs-unit-artwork' src={unit.attributes.artwork_img_url} alt={unit.attributes.name}/>
      <AddUnitForm unit={unit}/>
    </section>
  );
}

export default AddUnit;
