import React from 'react';

const LuckIconSvg = ({ size = "120", number = '123' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter id="shadow" x="-20%" y="-20%" width="140%" height="140%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
          <feOffset dx="4" dy="4"/>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/>
          </feComponentTransfer>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <linearGradient id="circleGradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{stopColor:"rgba(2,0,36,1)", stopOpacity:1}} />
          <stop offset="35%" style={{stopColor:"rgba(9,9,121,1)", stopOpacity:1}} />
          <stop offset="100%" style={{stopColor:"rgba(0,212,255,1)", stopOpacity:1}} />
        </linearGradient>
      </defs>
      <circle cx="50" cy="60" r="30" stroke="white" strokeWidth="1.5" fill="url(#circleGradient)" />
      <text x="42%" y="45%" alignmentBaseline="middle" textAnchor="middle" fill="white" fontSize="30" fontFamily="Arial" fontWeight="bolder" dy=".3em" stroke="black" strokeWidth="1.5" letterSpacing="-1">{number}</text>
      <text x="41%" y="38" textAnchor="middle" fill="#F9F871" fontSize="25" fontFamily="Arial" fontWeight="bolder" letterSpacing="-3" stroke="#0F0F0F" strokeWidth="2.5" filter="url(#shadow)">LUCK</text>
    </svg>
  );
};

export default LuckIconSvg;
