import React from 'react';
import UnitPreview from '../UnitPreview/UnitPreview'
import './UnitsList.scss';

function UnitsList({ units, addUnitClick, viewUnitClick }) {
  return (
    <div id='gs-unit-list'>
      {units.map((unit) => (
        <UnitPreview key={unit.id} unit={unit} addUnitClick={addUnitClick} viewUnitClick={viewUnitClick} />
      ))}
    </div>
  );
}

export default UnitsList;
