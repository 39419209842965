import React from 'react';

// Data Display Row Component
function DataDisplayRow({ name, value }) {
  return (
    <div className="gs-data-display-row">
      <strong>{name}:</strong> <span>{value}</span>
    </div>
  );
}

export default DataDisplayRow;
