import React from 'react';
import './EquipCard.scss';
import EquipCardHeading from './EquipCardHeading/EquipCardHeading'
import EquipMainSection from './EquipMainSection/EquipMainSection'
import { EquipCardRarityStars, EquipStatList, EquipUnits } from '../../../equips';

function EquipCard({ equip }) {
  const equipData = equip.attributes
  const equipStats = {
    hp: equipData.hp,
    atk: equipData.atk,
    def: equipData.defence
  }

  return (
    <section className='gs-user-equip-card'>
      <EquipCardHeading name={equipData.name} lb={equipData.lb} lvl={equipData.lvl} equipment_type={equipData.equipment_type} />
      <img className='gs-equip-artwork' src={equipData.max_artwork_img_url} alt={equipData.name}/>
      <EquipCardRarityStars rarity={equipData.rarity} />
      <EquipStatList stats={equipStats}/>
      <EquipMainSection equip={equipData}/>
      <EquipUnits units={equipData.units} />
    </section>
  );
}

export default EquipCard;
