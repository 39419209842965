import React from 'react';
import './AddUnitVersions.scss';
import AddUnit from '../AddUnit'
import useUnitShow from '../../../../../hooks/units/useUnitShow';
import { useSidePanel } from '../../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../../context/FlashMessageContext';

function AddUnitVersions({ unitVersions }) {
  const { showUnit } = useUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const versionTxt = {
    '1_star': '1☆',
    '2_star': '2☆',
    '3_star': '3☆',
    '4_star': '4☆',
    '5_star': '5☆',
    'awk': 'AWK',
    'asc': 'ACSD',
    'sp': 'SP',
    'fire_dream': 'F.dr',
    'earth_dream': 'E.dr',
    'water_dream': 'W.dr',
    'light_dream': 'L.dr',
    'dark_dream': 'D.dr',
  }

  const viewUnitVersion = (unitId) => {
    showUnit(unitId)
      .then(unit => {
        openSidePanel(<AddUnit unit={unit.data} unitVersions={unitVersions}/>);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', error);
      });
  };

  return (
    <ul className='gs-add-unit-versions'>
      {
        unitVersions.map((unit, index) => (
          <li key={index} onClick={() => viewUnitVersion(unit.unit_id)}>
            {versionTxt[unit.version]}
          </li>
        ))
      }
    </ul>
  );
}

export default AddUnitVersions;
