import React from 'react';
import { useFlashMessage } from '../../../context/FlashMessageContext';
import './FlashMessagesDisplay.scss';

const FlashMessagesDisplay = () => {
  const { messages, removeMessage } = useFlashMessage();

  return (
    <div className='gs-flash-messages-container'>
      {messages.map((message) => (
        <div key={message.id} className={`gs-flash-message gs-alert-${message.type}`} onClick={() => removeMessage(message.id)}>
          {message.text}
        </div>
      ))}
    </div>
  );
};

export default FlashMessagesDisplay;
