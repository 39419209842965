import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('authToken');
      } else {
        setCurrentUser(decodedToken);
      }
    }
  }, []);

  const isLoggedIn = () => {
    return !!currentUser;
  };

  const logout = async () => {
    localStorage.removeItem('authToken');
    setCurrentUser(null);
  };

  return (
    <AuthContext.Provider value={{ currentUser, isLoggedIn, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
