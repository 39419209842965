import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;

function useUserUnitDelete() {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const deleteUnit = (unitId) => {
    setLoading(true);
    setError(null); // Reset error state

    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      console.error('Authentication token not found');
      setError(new Error('Authentication token not found'));
      setLoading(false);
      return Promise.reject('Authentication token not found'); // Return rejected promise
    }

    return fetch(`${BASE_URL}/user_units/${unitId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json(); // Assuming the server responds with JSON
    })
    .then(data => {
      setLoading(false);
      return data; // Resolve the promise with the response data
    })
    .catch(error => {
      console.error('Error:', error);
      setError(error);
      setLoading(false);
      return Promise.reject(error); // Reject the promise with the error
    });
  };

  return { deleteUnit, isLoading, error };
}

export default useUserUnitDelete;
