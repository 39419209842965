import { useState } from 'react';

const BASE_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const useEquipShow = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const showEquip = (equipId) => {
    if (!equipId) {
      setError(new Error('Equip ID is required'));
      setLoading(false);
      return Promise.reject('Equip ID is required');
    }

    return fetch(`${BASE_URL}/equips/${equipId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': API_KEY,
      },
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      setLoading(false);
      return data;
    })
    .catch(error => {
      console.error(`Failed to fetch equip with ID ${equipId}:`, error);
      setError(error);
      setLoading(false);
      return Promise.reject(error);
    });
  };

  return { showEquip, loading, error };
};

export default useEquipShow;
