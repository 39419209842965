import React from 'react';
import './MinMaxControls.scss';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';

function MinMaxControls({ setFormData, equip }) {
  const setMaxValues = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      lvl: equip.attributes.max_lvl.toString(),
      lb: equip.attributes.max_lb.toString(),
    }));
  };

  const setMinValues = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      lvl: '1',
      lb: '0',
    }));
  };

  return (
    <div className='gs-add-equip-form-controls'>
      <button className='gs-add-equip-max-btn' onClick={setMaxValues} title='max stats'>
        <AddCircleOutline />
      </button>
      <button className='gs-add-equip-min-btn' onClick={setMinValues} title='min stats'>
        <RemoveCircleOutline />
      </button>
    </div>
  );
}

export default MinMaxControls;
