import React from 'react';
import './UnitCardHeading.scss';
import LbIconSvg from '../../../../common/LbIconSvg';
import LuckIconSvg from '../../../../common/LuckIconSvg';

function UnitCardHeading({ name, lvl, lb, luck }) {
  return (
    <div className='gs-user-unit-card-heading'>
      <LbIconSvg size={100} number={lb} />
      <div className='gs-user-unit-title'>
        <h2>{name}</h2>
        <small><b>LV: {lvl}</b></small>
      </div>
      <LuckIconSvg size={100} number={luck} />
    </div>
  );
}

export default UnitCardHeading;
