import React from 'react';
import './EquipPassive.scss';
import Panel from '../../../common/Panel'

function EquipPassive({ passive }) {
  return (
    <Panel width='100%'>
      {passive.description}
    </Panel>
  );
}

export default EquipPassive;
