import React, { useState } from 'react';
import './EquipBox.scss';
import EquipCard from '../EquipCard/EquipCard';
import EquipPreview from '../EquipPreview/EquipPreview'
import { useSidePanel } from '../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../context/FlashMessageContext';
import useEquipIndex from '../../../../hooks/userEquips/useEquipIndex';
import useUserEquipShow from '../../../../hooks/userEquips/useUserEquipShow';

function EquipBox({ mode }) {
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const { userEquips, loading: indexLoading, error: indexError } = useEquipIndex();
  const { showUserEquip, error: showError } = useUserEquipShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const viewEquipClick = (previewEquip) => {
    showUserEquip(previewEquip.id)
      .then(equip => {
        openSidePanel(<EquipCard equip={equip.data} />);
      })
      .catch(error => {
        addMessage('Failed to load equip', 'danger');
        console.error('Error:', showError);
      });
  };

  const handleRefresh = () => {
    setRefreshTrigger(!refreshTrigger); // Toggle the trigger to force re-fetching user units
  };

  if (indexLoading) return <p>Loading equips...</p>;
  if (indexError) return <p>Error loading equips: {indexError.message}</p>;

  return (
    <div id='gs-user-equip-box'>
      {userEquips.data.map((equip) => (
        <EquipPreview
          key={equip.id}
          equip={equip}
          viewEquipClick={viewEquipClick}
          refreshEquips={handleRefresh}
          mode={mode}/>
      ))}
    </div>
  );
}

export default EquipBox;
