import React from 'react';
import './UnitVersions.scss';
import UnitCard from '../UnitCard'
import useUnitShow from '../../../../hooks/units/useUnitShow';
import { useSidePanel } from '../../../../context/SidePanelContext';
import { useFlashMessage } from '../../../../context/FlashMessageContext';
import darkDream from '../../../../assets/images/icons/dream/dark_dream.png';
import lightDream from '../../../../assets/images/icons/dream/light_dream.png';
import earthDream from '../../../../assets/images/icons/dream/earth_dream.png';
import waterDream from '../../../../assets/images/icons/dream/water_dream.png';
import fireDream from '../../../../assets/images/icons/dream/fire_dream.png';

function UnitVersions({ unitVersions }) {
  const { showUnit } = useUnitShow();
  const { openSidePanel } = useSidePanel();
  const { addMessage } = useFlashMessage();

  const versionTxt = {
    '1_star': '1☆',
    '2_star': '2☆',
    '3_star': '3☆',
    '4_star': '4☆',
    '5_star': '5☆',
    'awk': 'AWK',
    'ascd': 'ACSD',
    'sp': 'SP',
    'fire_dream': 'F.dr',
    'earth_dream': 'E.dr',
    'water_dream': 'W.dr',
    'light_dream': 'L.dr',
    'dark_dream': 'D.dr',
  }

  const getDreamImg = (dreamType) => {
    switch(dreamType) {
      case 'fire_dream': return fireDream
      case 'earth_dream': return earthDream
      case 'water_dream': return waterDream
      case 'light_dream': return lightDream
      case 'dark_dream': return darkDream
      default: return darkDream;
    }
  }

  const isDreamVersion = (version) => {
    const dreamTypes = ['fire_dream', 'earth_dream', 'water_dream', 'light_dream', 'dark_dream'];
    return dreamTypes.includes(version);
  }

  const viewUnitVersion = (unitId) => {
    showUnit(unitId)
      .then(unit => {
        addMessage('Unit Loaded', 'success');
        openSidePanel(<UnitCard unit={unit.data} unitVersions={unitVersions}/>);
      })
      .catch(error => {
        addMessage('Failed to load unit', 'danger');
        console.error('Error:', error);
      });
  };

  return (
    <ul className='gs-unit-versions'>
      {
        unitVersions.map((unit, index) => (
          <li key={index} onClick={() => viewUnitVersion(unit.unit_id)}>
            {isDreamVersion(unit.version) && <img src={getDreamImg(unit.version)} alt={unit.version} />}
            {!isDreamVersion(unit.version) && <span>{versionTxt[unit.version]}</span>}
          </li>
        ))
      }
    </ul>
  );
}

export default UnitVersions;
