import React, { useState } from 'react';
import './DeleteIcon.scss';
import { RemoveCircleOutlineOutlined } from '@mui/icons-material';
import DialogBox from '../../../../common/DialogBox';
import useUserEquipDelete from '../../../../../hooks/userEquips/useUserEquipDelete';
import { useFlashMessage } from '../../../../../context/FlashMessageContext';

function DeleteIcon({ equip, refreshEquips }) {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { deleteEquip, isLoading } = useUserEquipDelete();
  const { addMessage } = useFlashMessage();

  const confirmDelete = () => {
    deleteEquip(equip.id)
      .then(() => {
        addMessage(`Successfuly ${equip.attributes.name} removed`, 'success');
        setShowConfirmDialog(false);
        refreshEquips()
      })
      .catch(error => {
        addMessage(`failed to remove ${equip.attributes.name}`, 'danger');
      });
  };

  return (
    <>
      <div className='gs-user-equip-delete'>
        <RemoveCircleOutlineOutlined onClick={() => setShowConfirmDialog(true)}/>
      </div>
      {showConfirmDialog && <DialogBox isOpen={showConfirmDialog} closeModal={() => setShowConfirmDialog(false)}>
          <p>Are you sure you want to delete {equip.attributes.name}?</p>
          <button className='primary' onClick={confirmDelete} disabled={isLoading}>YES</button>
          <button className='secondary' onClick={() => setShowConfirmDialog(false)}>NO</button>
      </DialogBox>}
    </>
  );
}

export default DeleteIcon;
